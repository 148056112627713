<template>
    <!-- Salary Card -->
    <a-card :bordered="false" class="widget-2 h-full" style="margin-bottom: 13px; box-shadow: inset;">

        <template #title>

            <i class="fa fa-shopping-cart" aria-hidden="true"></i>

        </template>

        <div style="margin: 13px;">

            <a-descriptions :bordered="false" size="small" :column="1">

                <a-descriptions-item label="Title">{{ customer_name }}</a-descriptions-item>

                <a-descriptions-item label="Product">{{ product_name }}</a-descriptions-item>

                <a-descriptions-item label="Amount" v-if="amount">{{ amount }}</a-descriptions-item>
               
            </a-descriptions>

            <a-button style="width: 100%;" type="primary" @click="viewCustomer">View</a-button>


        </div>



    </a-card>
    <!-- / Salary Card -->
</template>

<script>

export default ({
    props: {
        id: {
            type: String,
            default: "",
        },
        customer_name: {
            type: String,
            default: "",
        },
        product_name: {
            type: String,
            default: "",
        },
        amount: {
            type: Number,
            default: 0,
        },
    },
    computed : {
        title() {
            return this.name;
        },
    },
    methods: {
        viewCustomer() {
            console.log("view orders");
            this.$router.push("/orders/" + this.id);
        }
    },
    data() {
        return {
        }
    },
})

</script>